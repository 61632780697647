/** @jsx jsx */
import { Box, jsx, Grid } from "theme-ui";
import React, { useCallback } from "react";
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import { Link } from "gatsby";
import DonationLayout from "../layout/DonationLayout";
import { eventCallbackTriggers } from "../templates/wppage";
import banner from "../../static/imgs/heartfulness-green-donation-banner.jpg";

const RecurringGeneralDonationSMSF = () => {
  const pageContent = (id, modalTitle) => {
    const titletext = modalTitle || "General Donation Recurring SMSF, USA";
    return `<gatsby_donation donationId = ${id} btntext = "${titletext}" btnBg = #4d8f15 colortext = black colorprimary = forestgreen colorsecondary = darkgreen colorbackground = white colormuted = #f6f6f6 colorhighlight = #efeffe titletext = "General Donation Recurring - SMSF, USA" />`;
  };

  const PageContent = React.memo(PageContentNonMemoized);

  const eventCallback = useCallback(
    (event) =>
      eventCallbackTriggers("General_Donation_Recurring_SMSF,_USA", event),
    []
  );

  return (
    <>
      <DonationLayout
        seoData={{
          title: "Heartfulness Donations",
        }}
      >
        <Box
          sx={{
            paddingTop: "30px",
            alignItems: "center",
            minHeight: "620px",
            maxHeight: "620px",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${banner})`,
          }}
        >
          <Box sx={{ paddingBottom: "30px" }}>
            <h2
              sx={{
                color: "White",
                fontWeight: "500",
                lineHeight: "1.2",
                fontSize: "55px",
                textAlign: "center!important",
              }}
            >
              SMSF USA Donations – Recurring
            </h2>
            <PageContent
              eventCallback={eventCallback}
              pageContent={pageContent(24, "Donate Now")}
            />
          </Box>
        </Box>
        <Grid style={{ marginBottom: "15px", padding: "20px 30px" }}>
          <Box>
            <h1
              sx={{
                marginTop: "15px",
                fontWeight: "600",
                lineHeight: "1.2",
                fontSize: "1.5em",
                marginBottom: "0.5rem",
                textAlign: "center!important",
              }}
            >
              General Donation to SMSF – Recurring, USA
            </h1>
            <p
              sx={{
                fontWeight: "400",
                lineHeight: "1.2",
                fontSize: "18px",
                marginBottom: "1rem",
                textAlign: "justify",
              }}
            >
              All donations to Sahaj Marg Spirituality Foundation are voluntary.
              Donated funds are used to:
            </p>
            <ul>
              <li style={{ marginBottom: "0.5rem" }}>
                Host seminars and gatherings in the state of Texas.
              </li>
              <li style={{ marginBottom: "0.5rem" }}>
                Support education, research, and publications in the field of
                spirituality.
              </li>
              <li style={{ marginBottom: "0.5rem" }}>
                Offer grants to similar non-profit organizations to promote
                heartfulness meditation to countries around the globe.
              </li>
              <li style={{ marginBottom: "0.5rem" }}>
                Support Environment and Sustainability initiatives (Heartfulness
                Green movement).
              </li>
            </ul>
          </Box>
          <Box>
            <div
              className="row"
              style={{ alignItems: "center", justifyContent: "center" }}
            >
              <div
                className="col-lg-4 col-md-12 col-sm-12 col-xs-12 text-center"
                style={{ marginTop: "15px" }}
              >
                <PageContent
                  eventCallback={eventCallback}
                  pageContent={pageContent(24, "Donate Now")}
                />
              </div>
              <div
                className="col-lg-4 col-md-12 col-sm-12 col-xs-12 text-center"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "15px",
                  padding: "8px",
                }}
              >
                <Box
                  sx={{
                    cursor: "pointer",
                    backgroundColor: "rgb(77, 143, 21)",
                    padding: "8px 25px",
                    borderRadius: "25px",
                    color: "white",
                    border: "0px",
                    width: "190px",
                    height: "38px",
                  }}
                >
                  <Link
                    to="/recurring-general-donation-to-srcm-usa"
                    sx={{
                      color: "white",
                      ":hover": {
                        color: "white",
                        textDecoration: "none",
                      },
                    }}
                  >
                    Donate To SRCM
                  </Link>
                </Box>
              </div>
              <div
                className="col-lg-4 col-md-12 col-sm-12 col-xs-12 text-center"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "15px",
                  padding: "8px",
                }}
              >
                <Box
                  sx={{
                    cursor: "pointer",
                    backgroundColor: "rgb(77, 143, 21)",
                    padding: "8px",
                    borderRadius: "25px",
                    color: "white",
                    border: "0px",
                    width: "160px",
                    height: "38px",
                  }}
                >
                  <Link
                    to="/general-donations-to-heartfulness-institute-usa-recurring"
                    sx={{
                      color: "white",
                      ":hover": {
                        color: "white",
                        textDecoration: "none",
                      },
                    }}
                  >
                    Donate To HI
                  </Link>
                </Box>
              </div>
            </div>
          </Box>
        </Grid>
      </DonationLayout>
    </>
  );
};

export default RecurringGeneralDonationSMSF;
